var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate } from "react-router-dom";
import { NEW_URL } from "../../../api/API";
import style from "./companyRowElement.module.css";
import Checkbox_icon from "../../../assets/img/official.svg";
import { useRedirectToProfileDependsOnWhoYouAre } from "../../RedirectToProfileDependsOnWhoYouAre/RedurectToProfilesForCreator";
import { useAppDispatch } from "../../Card/cardImports";
import { setToggleCompanyByIdBlockButtons } from "../../../redux/reducers/companyByIdReducer/companyByIdReducer.";
var CompanyRowElement = function (_a) {
    var data = _a.data, withVacancies = _a.withVacancies;
    var redirectToProfile = useRedirectToProfileDependsOnWhoYouAre(data === null || data === void 0 ? void 0 : data.type, data === null || data === void 0 ? void 0 : data.id, data === null || data === void 0 ? void 0 : data.id_full, data === null || data === void 0 ? void 0 : data.visible_menu);
    var location = useLocation();
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var handleCheckDisplayedUserName = function (typeUser) {
        switch (typeUser) {
            case "expertProfile/":
                return "".concat(data === null || data === void 0 ? void 0 : data.first_name, " ").concat(data === null || data === void 0 ? void 0 : data.last_name);
            case "companyProfile/":
                return "".concat(data.name);
            case "userProfile/":
                return "".concat(data === null || data === void 0 ? void 0 : data.name);
        }
    };
    var handleSetActiveVacancy = function () {
        if (location.pathname === "/vacancy/") {
            setTimeout(function () {
                dispatch(setToggleCompanyByIdBlockButtons(3));
            }, 50);
        }
        else {
            return;
        }
    };
    return (_jsxs("div", __assign({ className: style.companyRowElement, onClick: function () {
            navigate(redirectToProfile);
            handleSetActiveVacancy();
        } }, { children: [_jsx("img", { className: style.imgStyle, src: "".concat(NEW_URL).concat(data === null || data === void 0 ? void 0 : data.avatar) }), _jsx("p", __assign({ className: style.companyName }, { children: handleCheckDisplayedUserName(data === null || data === void 0 ? void 0 : data.type) })), withVacancies ? _jsx("div", { children: (data === null || data === void 0 ? void 0 : data.verifided) && _jsx("img", { className: style.checkBoxIcon, src: Checkbox_icon }) }) : null, withVacancies && (data === null || data === void 0 ? void 0 : data.vacancy_count) !== null ? _jsx("p", __assign({ className: style.vacancy_count }, { children: "(".concat(data.vacancy_count, ")") })) : null] })));
};
export default CompanyRowElement;
