var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import style from "./SearchBlock.module.css";
import Search from "../../assets/img/Search.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { setSearchWord } from "../../redux/globalSearchReducer";
import { PATH } from "../../constants";
import { useTranslation } from "react-i18next";
export var SearchBlock = function () {
    var dispatch = useAppDispatch();
    var location = useLocation();
    var navigate = useNavigate();
    var t = useTranslation("common").t;
    var searchWord = useSelector(function (state) { return state.globalSearchReducer.searchWord; });
    var SubmitFunck = function () {
        if (searchWord.length === 0)
            return;
        dispatch(setSearchWord({ word: searchWord }));
    };
    var ChangeValue = function (value) {
        dispatch(setSearchWord({ word: value }));
    };
    var handleKeyPress = function (event) {
        if (event.key === "Enter") {
            dispatch(setSearchWord({ word: searchWord }));
            navigate(PATH.search_result);
        }
    };
    useEffect(function () {
        if (location.pathname !== "/search_result_page") {
            dispatch(setSearchWord({ word: "" }));
        }
    }, [location]);
    return (_jsx("div", __assign({ className: style.middle_block }, { children: _jsxs("div", __assign({ className: style.container_input }, { children: [(searchWord === null || searchWord === void 0 ? void 0 : searchWord.length) === 0 ? (_jsx("img", { className: style.icon_input, src: Search })) : (_jsx(Link, __assign({ onClick: SubmitFunck, to: "/search_result_page" }, { children: _jsx("img", { className: style.icon_input, src: Search }) }))), _jsx("input", { className: style.input_Search, placeholder: "Поиск", type: "text", onChange: function (event) {
                        ChangeValue(event.target.value);
                    }, onKeyDown: handleKeyPress, value: searchWord, autoComplete: "one-time-code" })] })) })));
};
