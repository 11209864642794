var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import style from "./searchCompaniesLittleBlock.module.css";
import CompanyRowElement from "./companyRowElement/companyRowElement";
import { fetchTopCompanies, setSerchCompanyValue } from "../../redux/searchCompanyReducer";
import { useAppDispatch } from "../../redux/store";
import { setActive } from "../../redux/modalReducer";
import { useMediaQuery } from "react-responsive";
import { useQuery } from "react-query";
import { fetchTopRatedCompaniesWithVacancies } from "@src/api/topRatedUnitsApi/topRatedUnitsThunks";
export var SearchCompaniesLittleBlockData = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var vacansiesTitle = _a.vacansiesTitle, withVacancies = _a.withVacancies;
    var isPlanshet = useMediaQuery({
        query: "(min-width: 1241px)",
    });
    // const searchLittleValue = useSelector<RootState, any>((state) => state.searchUsersReducer.searchCompanyValue);
    var topRatedCompaniesResult = useQuery(["fetchTopCompaniesInLittleBlock"], fetchTopCompanies);
    var topRatedCompaniesWithVacancies = useQuery(["fetchTopRatedCompaniesWithVacancies"], fetchTopRatedCompaniesWithVacancies);
    // const searchedCompaniesDataResult = useQuery(["fetchCompaniesInLittleBlock", searchLittleValue, withVacancies], () => fetchCompaniesInLittleBlock(searchLittleValue, 1, withVacancies));
    var dispatch = useAppDispatch();
    var handleCloseFilter = function () {
        dispatch(setActive(false));
    };
    var onChangeValue = function (value) {
        dispatch(setSerchCompanyValue({ searchCompanyValue: value }));
    };
    if (isPlanshet) {
        handleCloseFilter();
    }
    return (_jsxs("div", __assign({ className: style.littleSearch_block }, { children: [_jsx("h1", __assign({ className: style.filterName }, { children: vacansiesTitle ? vacansiesTitle : "Компании" })), _jsxs("div", __assign({ className: style.resultSearchBlock, onClick: function () { return handleCloseFilter(); } }, { children: [withVacancies ? (_jsx(_Fragment, { children: (_c = (_b = topRatedCompaniesWithVacancies === null || topRatedCompaniesWithVacancies === void 0 ? void 0 : topRatedCompaniesWithVacancies.data) === null || _b === void 0 ? void 0 : _b.slice(0, 7)) === null || _c === void 0 ? void 0 : _c.map(function (item) {
                            if (item === null || item === void 0 ? void 0 : item.company) {
                                return _jsx(CompanyRowElement, { data: item === null || item === void 0 ? void 0 : item.company, withVacancies: withVacancies }, item === null || item === void 0 ? void 0 : item.id);
                            }
                        }) })) : (_jsx(_Fragment, { children: (_e = (_d = topRatedCompaniesResult === null || topRatedCompaniesResult === void 0 ? void 0 : topRatedCompaniesResult.data) === null || _d === void 0 ? void 0 : _d.slice(0, 7)) === null || _e === void 0 ? void 0 : _e.map(function (item) {
                            if (item === null || item === void 0 ? void 0 : item.company) {
                                return _jsx(CompanyRowElement, { data: item === null || item === void 0 ? void 0 : item.company, withVacancies: withVacancies }, item === null || item === void 0 ? void 0 : item.id);
                            }
                        }) })), ((_f = topRatedCompaniesResult === null || topRatedCompaniesResult === void 0 ? void 0 : topRatedCompaniesResult.data) === null || _f === void 0 ? void 0 : _f.length) === 0 || ((_g = topRatedCompaniesResult === null || topRatedCompaniesResult === void 0 ? void 0 : topRatedCompaniesResult.data) === null || _g === void 0 ? void 0 : _g.length) === 0 ? _jsx("p", __assign({ className: style.error_search_text }, { children: "\u0423\u043F\u0441... \u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E." })) : null] })), _jsx(Link, __assign({ onClick: function () { return handleCloseFilter(); }, className: style.linkToCompaniesPage, to: "/companies_serch_page/" }, { children: "\u0412\u0441\u0435 \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438" }))] })));
};
export default SearchCompaniesLittleBlockData;
