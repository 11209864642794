// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jmLxdSlqGURKKLKh20EQ {
  display: flex;
  width: 100%;
  max-width: 660px;
  transform: translate(-5px, 0px);
  margin-right: 70px;
}

.qZfgsbi8_aCt3zfdQZgO {
  width: 100%;
  /* 411px */
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  background: #f8fbfd;
}

.SIZJQjk35JGREX7twu6Q {
  display: flex;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-left: 21px;
  margin-right: 10px;
}

.bVop6D1pQuzmr6Tts8cA {
  width: 90%;
  outline: none;
  border: none;
  background-color: #f8fbfd;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;

  color: var(--main-Gray-3-color);
}

@media (max-width: 480px) {
  .jmLxdSlqGURKKLKh20EQ {
    width: 96vw;
    position: absolute;
    transform: translateX(-37%);
  }

  .qZfgsbi8_aCt3zfdQZgO {
    width: 100%;
  }

  .bVop6D1pQuzmr6Tts8cA {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBlock/SearchBlock.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,qCAAqC;EACrC,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,YAAY;EACZ,yBAAyB;;EAEzB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,qBAAqB;;EAErB,+BAA+B;AACjC;;AAEA;EACE;IACE,WAAW;IACX,kBAAkB;IAClB,2BAA2B;EAC7B;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".middle_block {\n  display: flex;\n  width: 100%;\n  max-width: 660px;\n  transform: translate(-5px, 0px);\n  margin-right: 70px;\n}\n\n.container_input {\n  width: 100%;\n  /* 411px */\n  height: 40px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border: 1px solid rgba(0, 0, 0, 0.03);\n  border-radius: 12px;\n  background: #f8fbfd;\n}\n\n.icon_input {\n  display: flex;\n  align-items: center;\n  width: 22px;\n  height: 22px;\n  margin-left: 21px;\n  margin-right: 10px;\n}\n\n.input_Search {\n  width: 90%;\n  outline: none;\n  border: none;\n  background-color: #f8fbfd;\n\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 300;\n  font-size: 14px;\n  line-height: 16px;\n  letter-spacing: 0.1px;\n\n  color: var(--main-Gray-3-color);\n}\n\n@media (max-width: 480px) {\n  .middle_block {\n    width: 96vw;\n    position: absolute;\n    transform: translateX(-37%);\n  }\n\n  .container_input {\n    width: 100%;\n  }\n\n  .input_Search {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"middle_block": `jmLxdSlqGURKKLKh20EQ`,
	"container_input": `qZfgsbi8_aCt3zfdQZgO`,
	"icon_input": `SIZJQjk35JGREX7twu6Q`,
	"input_Search": `bVop6D1pQuzmr6Tts8cA`
};
export default ___CSS_LOADER_EXPORT___;
