// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TahOXaa_zne3X7xtg_Pj {
  display: flex;
}
.B_vPgV6vTymyXbdTe4c2 {
  width: 90%; /* 411px */
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  background: #f8fbfd;
}
.eM7EKR0p_m53GzwiOXzE {
  width: 24px;
  height: 24px;
  margin-left: 23px;
  margin-right: 23px;
}

.C7w1wns6NxkdxeqQtLw5 {
  width: 300px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #f8fbfd;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.1px;

  color: var(--main-Gray-3-color);
}

@media (max-width: 480px) {
  .TahOXaa_zne3X7xtg_Pj {
    width: 97vw;
    position: absolute;
    top: 8.5px;
    left: 5px;
    z-index: 1;
  }

  .B_vPgV6vTymyXbdTe4c2 {
    width: 100%;
  }

  .C7w1wns6NxkdxeqQtLw5 {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchBlock/SearchBlockForMobile/SearchBlockForMobile.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,UAAU,EAAE,UAAU;EACtB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;;EAEnB,qCAAqC;EACrC,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,eAAe;EACf,yBAAyB;;EAEzB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,qBAAqB;;EAErB,+BAA+B;AACjC;;AAEA;EACE;IACE,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".middle_block {\n  display: flex;\n}\n.container_input {\n  width: 90%; /* 411px */\n  height: 44px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  border: 1px solid rgba(0, 0, 0, 0.03);\n  border-radius: 12px;\n  background: #f8fbfd;\n}\n.icon_input {\n  width: 24px;\n  height: 24px;\n  margin-left: 23px;\n  margin-right: 23px;\n}\n\n.input_Search {\n  width: 300px;\n  outline: none;\n  border: none;\n  cursor: pointer;\n  background-color: #f8fbfd;\n\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 32px;\n  letter-spacing: 0.1px;\n\n  color: var(--main-Gray-3-color);\n}\n\n@media (max-width: 480px) {\n  .middle_block {\n    width: 97vw;\n    position: absolute;\n    top: 8.5px;\n    left: 5px;\n    z-index: 1;\n  }\n\n  .container_input {\n    width: 100%;\n  }\n\n  .input_Search {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"middle_block": `TahOXaa_zne3X7xtg_Pj`,
	"container_input": `B_vPgV6vTymyXbdTe4c2`,
	"icon_input": `eM7EKR0p_m53GzwiOXzE`,
	"input_Search": `C7w1wns6NxkdxeqQtLw5`
};
export default ___CSS_LOADER_EXPORT___;
