var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from "./SearchErrorBlock.module.css";
// import searchError from '../../assets/img/searchError.jpg'
import nothingFound from '../../assets/img/nothingFound.png';
export function SearchErrorBlock() {
    return (_jsx("div", __assign({ className: style.error_block_wrapper }, { children: _jsxs("div", __assign({ className: style.error_block }, { children: [_jsxs("p", __assign({ className: style.error_search_text }, { children: ["\u0418\u0441\u0442\u0438\u043D\u0430 \u0433\u0434\u0435-\u0442\u043E \u0440\u044F\u0434\u043E\u043C, \u0430 \u043F\u043E\u043A\u0430 ", _jsx("span", __assign({ className: style.style_text }, { children: "\u043D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E " })), ".", _jsx("br", {}), "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043F\u043E\u0438\u0441\u043A\u043E\u0432\u044B\u0439 \u0437\u0430\u043F\u0440\u043E\u0441."] })), _jsx("img", { className: style.error_img, src: nothingFound })] })) })));
}
;
